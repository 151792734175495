import jQuery from 'jquery';
import 'select2';

import extendDropdown from '../inc/extendDropdown';
import formatResult from '../inc/formatResult';

export default class Search {
    constructor(){
        let that = this;

        extendDropdown(jQuery);

        window.jQuery('.getorder-integrations-search').each(function(){
            jQuery(this).select2({
                placeholder: jQuery(this).data('placeholder'),
                theme: "bootstrap",
                minimumInputLength: 3,
                templateResult: formatResult,
                ajax: {
                    url: jQuery(this).data('ajax-url'),
                    dataType: 'json',
                    type: "POST",
                    data: function (params) {
                        return {
                            action: 'getorder_services_search',
                            searchString: params.term,
                            page: params.page || 1
                        };
                    },
                    processResults: function (data) {
                        return {
                          results: data.results,
                          pagination: data.pagination
                        };
                    }
                }
            });
    
            jQuery(this).on('select2:select', function (e){
                let data = e.params.data;
                window.location.href = data.url;
            });
        });
    }
}