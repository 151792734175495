import jQuery from 'jquery';
import 'select2';

import extendDropdown from '../inc/extendDropdown';
import formatResult from '../inc/formatResult';

export default class Pairing {
    selectedMaster = 0;

    constructor($root){
        let that = this;

        extendDropdown(jQuery);

        this.$root      = $root;
        this.$selects   = this.$root.find('.getorder-integrations-pairing-content-select');
        this.$master    = this.$selects.filter('[data-is-master="1"]');
        this.$slave     = this.$selects.filter('[data-is-master="0"]');
        
        if(this.$master.length <= 0 || this.$slave.length <= 0) return;

        this.$master    = jQuery(this.$master.first());
        this.$slave     = jQuery(this.$slave.first());

        this.$selects.each(function(){
            that.renderSelect(jQuery(this));
        });
    }

    renderSelect($select){
        let that = this;
        let isMaster = $select.data('is-master') == 1;

        $select.select2({
            theme: 'bootstrap',
            placeholder: $select.data('placeholder'),
            minimumInputLength: 0,
            templateResult: formatResult,
            templateSelection: formatResult,
            ajax: {
                url: $select.data('ajax-url'),
                dataType: 'json',
                type: "POST",
                data: function (params) {
                    let payload = {
                        action: 'getorder_services_search',
                        searchString: params.term,
                        page: params.page || 1,
                        isPairing: true,
                        isMaster: isMaster
                    };

                    if(!isMaster){
                        payload.selectedMaster = that.selectedMaster;
                    }

                    return payload;
                },
                processResults: function (data) {
                    return {
                      results: data.results,
                      pagination: data.pagination
                    };
                }
            }
        });

        $select.on('select2:select', function (e) {
            let data = e.params.data;
            if (isMaster){
                that.selectedMaster = data.id;
            }

            let selectedMasterData = that.$master.select2('data');
            selectedMasterData = (selectedMasterData.length > 0) ? selectedMasterData[0] : null;

            let selectedSlaveData = that.$slave.select2('data');
            selectedSlaveData = (selectedSlaveData.length > 0) ? selectedSlaveData[0] : null;

            if(selectedMasterData != null && selectedSlaveData != null){
                let masterLinked    = that.getItemData(selectedMasterData, 'linked').split(',').map(function(id){
                    return parseInt(id, 10);
                });

                let slaveCategoryId = parseInt(that.getItemData(selectedSlaveData, 'catid'), 10);

                if (!masterLinked.includes(slaveCategoryId)){
                    that.$slave.val('');
                    that.$slave.trigger('change');
                    return;
                }

                let masterBase      = that.getItemData(selectedMasterData, 'base');
                let slaveBase       = that.getItemData(selectedSlaveData, 'base');
                
                let url             = that.$master.data('integration-url').replace(/\/+$/, "") + '/' + masterBase + '-' + slaveBase;
                that.$root.addClass('animate');
                that.$root.find('[ref="goLink"]').attr('href', url);
            }
        });

        if(isMaster){
            let selectedData = $select.select2('data');
            if (selectedData.length > 0){
                that.selectedMaster = parseInt(selectedData[0].id, 10);
            }
        }
    }

    getItemData(data, key){
        if(data[key] !== undefined) return data[key];
        if(data.element !== undefined) return jQuery(data.element).data(key);
        return '';
    }
}