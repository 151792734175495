export default function(state){
    if(state.id == '' || state.id == undefined) return state.text;
    if(state.loading !== undefined && state.loading){
        return state.text;
    }

    let logo = (state.logo !== undefined && state.logo.length > 0) ? `<img src="${state.logo}" />` : '';
    if (logo.length <= 0 && state.element !== undefined){
        let logo_url = jQuery(state.element).data('logo');
        logo = (logo_url !== undefined && logo_url.length > 0) ? `<img src="${logo_url}" />` : '';
    }

    return jQuery(
        `<span class="select2-formatted-result">
            <span class="select2-formatted-result-logo">
                ${logo}
            </span>
            <span class="select2-formatted-result-text">${state.text}</span>
        </span>`
    );
}