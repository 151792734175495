import jQuery from "jquery";

export default class Catalog {
    constructor() {
        window.jQuery('.getorder-integrations-catalog').each(function(){
            let $catalog        = jQuery(this);
            let ajaxUrl         = $catalog.data('ajax-url');

            let $pager          = $catalog.siblings('.getorder-integrations-catalog-pager');
            let $loadedCount    = $pager.find('[ref="loadedCount"]');
            let $totalCount     = $pager.find('[ref="totalCount"]');
            let $loadMore       = $pager.find('[ref="loadMore"]');
            let $progressBar    = $pager.find('[ref="progressBar"]');

            let nextPage        = 1;
    
            let filtersData = {
                category: $catalog.data('category'),
                country: 0
            };

            if(window.location.hash !== ''){
                let hash = window.location.hash.replace('#', '');
                jQuery('.getorder-integrations-filter[data-filter-type="country"] a[data-slug="' + hash + '"]').each(function(){
                    jQuery(this).siblings().removeClass('active');
                    jQuery(this).addClass('active');
                    filtersData.country = parseInt(jQuery(this).data('id'));
                });
            }

            function loadCatalog(page){
                if(page == undefined) page = 1;

                $catalog.addClass('loading');
                $loadMore.removeClass('active');

                jQuery.ajax({
                    method: 'POST',
                    url: ajaxUrl,
                    data: {
                        action: 'getorder_services_filter',
                        filters: filtersData,
                        page: page
                    }
                }).done(function(data){
                    $catalog.removeClass('loading');

                    if(page == 1) $catalog.html('');
                    $catalog.append(data.html);

                    $loadedCount.text(data.loaded);
                    $totalCount.text(data.total);
                    $progressBar.css('max-width', ((data.loaded / data.total) * 100) + '%');

                    $pager.addClass('active');
                    if(data.loaded >= data.total){
                        nextPage = 0;
                        $loadMore.removeClass('active');
                    } else {
                        nextPage = page + 1;
                        $loadMore.addClass('active');
                    }
                });
            }
    
            jQuery('.getorder-integrations-filter').each(function(){
                let $filter         = jQuery(this);
                let $filterItems    = $filter.find('a');
                let filterType      = $filter.data('filter-type');
    
                $filterItems.on('click', function(e){
                    if(jQuery(this).attr('href') != 'javascript://') return;

                    let id      = jQuery(this).data('id');
                    let slug    = jQuery(this).data('slug');

                    $filterItems.removeClass('active');
                    jQuery(this).addClass('active');
    
                    filtersData[filterType] = id;

                    window.history.pushState({}, '', '#' + slug);
                    
                    $pager.removeClass('active');
                    $catalog.html('');

                    loadCatalog();
                });
            });

            $loadMore.on('click', function(){
                if(nextPage == 0) return;
                loadCatalog(nextPage);
            });

            loadCatalog();
        });
    }
}