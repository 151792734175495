import Catalog from './classes/Catalog';
import Pairing from './classes/Pairing';
import Search from './classes/Search';

window.jQuery(document).ready(function(){
    new Catalog();
    new Search();

    window.jQuery('.getorder-integrations-pairing').each(function(){
        new Pairing(jQuery(this));
    });
});

